export const lightTheme = {
  color_text: "#262626",
  color_heading: "#000000",
  color_bg: "#E3E6EC",

  color_theme: "rgba(79, 88, 182, 1.00)",
  color_gray: "rgb(139, 148, 158, 0.8)",
  color_nav_bg: "rgba(204, 209, 216, 0.8)",
  color_hover_bg: "rgba(27, 31, 35, .05)",
  color_description: `rgba(129, 147, 178, 1.00)`,

  color_info: "rgba(50, 154, 240, 1)",
  color_info_bg: "rgba(50, 154, 240, 0.4)",
  color_danger: "rgba(255, 201, 0, 1)",
  color_danger_bg: "rgba(255, 224, 102, 0.45)",
  color_table_bg: "rgba(255,255,255,0.1)",
  color_table_bg_secondary: "rgba(255, 255, 255, 0.4)",
  color_link: "#b9cdfb",

  gradient_theme:
    "linear-gradient(-5deg, rgba(14, 16, 64, 1), rgba(14, 16, 64, 0.5))",
  gradient_glass:
    "radial-gradient(100% 216.12% at 1.81% 0%, rgba(248, 248, 248, 0.7) 0%, rgba(248, 248, 248, 0.4) 100%)",
  gradient_glass_hover:
    "radial-gradient(100% 216.12% at 1.81% 0%, rgba(248, 248, 248, 0.9) 0%, rgba(248, 248, 248, 0.6) 100%)",
  border_glass: "1px solid rgba(255, 255, 255, 0.1)",
  border_footer: "1px solid rgba(255, 255, 255, 0.1)",
  border_cate: "1px solid rgb(139, 148, 158, 0.2)",
  border_tag: "2px solid rgba(40, 36, 47, 0.4)",
  border_table: "1px solid #c6cbd1",

  bg: "var(--bg-light)",
  box_shadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
};

export const darkTheme = {
  color_text: "#d8dee9",
  color_heading: "#FFFFFF",
  color_bg: "#040D21",

  color_theme: "rgba(156, 154, 237, 1)",
  color_gray: "rgba(139, 148, 158, 0.8)",
  color_nav_bg: "rgba(12, 22, 45, 0.8);",
  color_hover_bg: "rgba(255, 255, 255, .1)",
  color_description: `rgba(153, 167, 219, 0.8)`,

  color_info: "rgba(67, 170, 255, 1.00)",
  color_info_bg: "rgba(50, 154, 240, 0.45)",
  color_danger: "rgba(255, 224, 102, 1)",
  color_danger_bg: "rgba(255, 224, 102, 0.45)",
  color_table_bg: "rgba(255, 255, 255, 0.1)",
  color_table_bg_secondary: "rgba(255, 255, 255, 0.2)",
  color_link: "#3d517f",

  gradient_theme:
    "linear-gradient(5deg, rgba(226, 225, 255, 1), rgba(156, 154, 237, 0.5))",
  gradient_glass:
    "radial-gradient(100% 216.12% at 1.81% 0%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.1) 100%)",

  border_tag: "1px solid rgba(40, 36, 47, 0.86)",
  border_cate: "1px solid rgba(29,39,56,1)",
  border_glass: "1px solid rgba(255, 255, 255, 0.1)",
  border_table: "1px solid rgba(255, 255, 255, 0.2)",
  border_footer: "1px solid rgba(255, 255, 255, 0.1)",

  bg: "var(--bg-dark)",
  box_shadow: "none",
};
